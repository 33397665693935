.Common-FullsizeColorImage {
    .pimcore_editable_image {
        @apply relative;
        width: 100% !important;
        height: 100% !important;

        img {
            @apply absolute inset-0 w-full h-full object-cover object-center;
        }

        .pimcore_editable_image_alt {
            @apply rounded-b-5 z-10;

            > div {
                width: 100% !important;
            }
        }
    }
}
