.AB-FullsizeImageText {
    .text_wrapper{
        font-size: 16px;

        @media (min-width: 1280px) {
            font-size: 24px;
            
        }

        h2{
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 16px;
                
            @media (min-width: 1280px) {
                font-size: 40px;
                margin-bottom: 24px;
            }
        }
    }

    &.new_style{
        .img_wrapper{
            position: relative;
    
            &:after{
                content: '';
                background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0.5;
                    
                @media (min-width: 1280px) {
                    right: auto;
                    width: 65%;
                }
            }
        }

        .main_inner{
            @media (max-width: 1023px) {
                display: block;
            }
        }

        .content_wrapper{

            .content_inner{
                @media (min-width: 1024px) {
                    grid-column-end: 18;
                }

                .title_wrapper{
                    background: transparent;
                    border-radius: 0;
                    padding: 0;
        
                    .headline,
                    .headline .highlight{
                        @apply font-saa-series;
                        color: #fff;
                        font-size: 48px;
                            
                        @media (min-width: 1280px) {
                            font-size: 78px;
                        }
                    }
                }
            }
        }
    }

    .button{
        &.new{
            margin-top: 20px;
                            
            @media (min-width: 1280px) {
                margin-top: 32px;
            }
        }
    }
}
