@layer components {
    .home-hero-data {
        .card-rounded {
            &-small {
                @apply rounded-5 overflow-clip lg:rounded-8.5;
            }

            &-large {
                @apply rounded-10 overflow-clip lg:rounded-12;
            }

            &-large-stacked {
                @apply rounded-t-10 overflow-clip md:rounded-10 lg:rounded-12;
            }
        }
    }
}

section#home-hero {
    .img-editmode,
    .video-editmode,
    .pimcore_editable_video,
    .pimcore_editable_video_empty {
        width: 100% !important;
        height: auto !important;
        aspect-ratio: 376/526;
        @apply rounded-5 overflow-clip;

        img,
        video {
            @apply w-full h-full object-cover object-center;
        }
    }
}

section#stacked-cards {
    .mask-wrapper {
        > .pin-spacer {
            @apply pointer-events-none;
        }
    }

    .headline.wysiwyg {
        p {
            @apply relative overflow-clip;
        }

        span.line {
            @apply block;
        }

        .highlight {
            @apply text-60 font-saa-series xl:text-78;
            line-height: 1.1 !important;
        }
    }

    .background-img-editmode > .pimcore_editable_image,
    .transport-img-editmode > .pimcore_editable_image,
    .teaser-icon-editmode > .pimcore_editable_image,
    .teaser-img-editmode > .pimcore_editable_image {
        width: 100% !important;
        height: 100% !important;
        @apply absolute left-0 top-0;

        img {
            @apply w-full h-full object-cover object-right-top;
        }
    }

    .teaser-img-editmode > .pimcore_editable_image {
        @apply rounded-5 overflow-clip;

        img {
            @apply object-center;
        }
    }
}
