.AB-LogoList {
    margin-top: 48px;
    margin-bottom: 48px;

    @media (min-width: 768px) {
        margin-top: 110px;
        margin-bottom: 64px;
    }

    @media (min-width: 1280px) {
        margin-top: 96px;
        margin-bottom: 154px;
    }

    .images_wrapper{

        &:not(.edit){
            display: flex;
            justify-content: center;
            align-items: center;
    
            @media (min-width: 768px) {
                justify-content: space-between;
            }
            @media (min-width: 1280px) {
    
            }
            @media (max-width: 767px) {
                flex-wrap: wrap;
            }

            .image{
                @media (max-width: 767px) {
                    flex: 0 0 auto;
                    width: calc(100% / 3);
                }
            }
        }
        &.edit{
            & > div{
                display: flex;
                justify-content: center;
                justify-content: space-between;
            }
        }
    }
}
