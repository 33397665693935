header{
    .menu_button{
        padding-right: 12px;

        @media (min-width: 550px) {
            padding-right: 72px;
        }
        @media (min-width: 768px) {
            padding-right: 146px;
        }
        @media (min-width: 1024px) {
            padding-right: 148px;
        }
        @media (min-width: 1440px) {
            padding-right: 152px;
        }
    }

    .breadcrumbs{
        nav{
            @media (max-width: 767px) {
                min-width: 104px;
                border-width: 1.6px;

                .icon_wrapper{
                    margin-left: -1px;
                    margin-right: 0;

                    svg{
                        transform: rotate(90deg);
                    }
                }

                .text_wrapper{
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
        .home_link{
            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .header_links{
        display: flex;
        align-items: center;

        a{
            @media (min-width: 768px) {
                
            }

            & + a{
                margin-left: 8px;

                @media (min-width: 768px) {
                    margin-left: 32px;
                }
            }

            &.contact_link{
                @media (max-width: 767px) {
                    flex: 0 0 auto;
                    height: 32px;
                    width: 32px;
                }

                &:active,
                &:hover{
                    .svg{
                        background-color: #000;

                        svg{
                            filter: invert(1);
                        }
                    }
                }
                
                span{
                    @media (max-width: 767px) {
                        display: none;
                    }
                }

                .svg{
                    display: none;

                    @media (max-width: 767px) {
                        flex: 0 0 auto;
                        width: 32px;
                        height: 32px;
                        border: 1.6px solid #000;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: auto;
                        transition: all ease-in .1s;

                        svg{
                            width: 22px;
                            height: 22px;
                            transition: all ease-out .1s;
                        }
                    }
                }
            }

            &.login_link{
                flex: 0 0 auto;
                font-size: 0;
                height: 32px;
                width: 32px;
                display: inline-flex;
                align-items: center;
                text-decoration: none !important;

                @media (min-width: 768px) {
                    font-size: 16px;
                    width: 94px;
                    border: 1px solid #000;
                    border-radius: 16px;
                    padding-left: 16px;
                }
                @media (max-width: 767px) {
                    span{
                        display: none;
                    }
                }

                &:active,
                &:hover{
                    .svg{
                        background-color: #000;

                        svg{
                            filter: invert(1);
                        }
                    }
                }

                .svg{
                    flex: 0 0 auto;
                    width: 32px;
                    height: 32px;
                    border: 1.6px solid #000;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: auto;
                    transition: all ease-in .1s;

                    @media (min-width: 768px) {
                        border-width: 1px;
                        margin-right: -1px;
                    }

                    svg{
                        width: 14px;
                        height: 18px;
                        transition: all ease-out .1s;
    
                        &.desktop{
                            @media (max-width: 767px) {
                                display: none;
                            }
                        }
                        &.mobile{
                            @media (min-width: 768px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}