.AB-TwoColumnTextBoxes {
    &.new_style{
        background-color: #3E60CB;
        padding-top: 30px;
        padding-bottom: 30px;
        margin: 84px 0;
        border-radius: 40px;
                
        @media (min-width: 768px) {
            padding-top: 50px;
            padding-bottom: 50px;
            margin: 96px 0;
        }
        @media (min-width: 1280px) {
            padding-top: 64px;
            padding-bottom: 53px;
            margin: 120px 2%;
        }

        .container{
            display: flex;
            flex-wrap: wrap;
            
            .headline,
            .text,
            .flex-col{
                margin-bottom: 24px;
                line-height: 1.25;

                @media (min-width: 768px) {
                    justify-content: space-between;
                    flex: 0 0 auto;
                    width: 49%;
                    width: calc(50% - 1.25rem / 2);
                }
                @media (min-width: 1280px) {
                    margin-bottom: 57px;
                }
            }
            .headline,
            .text{
                @media (min-width: 768px) and (max-width: 1023px) {
                    width: 100%;
                }
            }

            .flex-col{
                padding: 30px 20px;
                aspect-ratio: auto;
                text-align: left;
                margin-bottom: 0;

                @media (min-width: 1280px) {
                    padding: 80px;
                }

                &:nth-last-child(2){
                    background: #87B5CB;
                }
                &:nth-last-child(1){
                    background: #6CA38B;
                }

                p{
                    color: #000;

                    @media (min-width: 1280px) {
                        margin-top: 32px;
                    }
                }

                .button{
                    margin-left: 0;
                    margin-right: auto;
                    font-size: 25px;
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (min-width: 1280px) {
                        margin-top: 62px;
                        font-size: 26px;
                        width: 290px;
                    }
                }

                .headline_inner{
                    font-size: 25px;
                    line-height: 1.25;
            
                    @media (min-width: 1280px) {
                        font-size: 40px;
                    }
                }
                p{
                    font-size: 16px;
                    line-height: 1.25;
            
                    @media (min-width: 1280px) {
                        font-size: 24px;
                        
                    }
                }
            }

            .headline{
                color: #fff;
                font-size: 48px;
                    
                @media (min-width: 1280px) {
                    font-size: 68px;
                }
        
                em{
                    font-style: normal;
                    text-transform: uppercase;
                    @apply font-saa-series;
                }
            }
            .text{
                color: #fff;
                font-size: 16px;
                margin-bottom: 36px;
        
                @media (min-width: 1280px) {
                    font-size: 24px;
                    
                }
            }
        }
    }
}
