.AB-TwoColumnHeadlineText {

    .headline{

        em{
            font-style: normal;
            text-transform: uppercase;
            @apply font-saa-series;
        }
    }
}
