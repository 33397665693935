.AB-Vacancies {
    margin-bottom: 48px;

    @media (min-width: 768px) {
        margin-bottom: 64px;
    }
    @media (min-width: 1280px) {
        margin-bottom: 82px;
    }
    
    &.is_disabled{
        opacity: 0.4;
    }

    .list_wrapper{
        .filters{
            background-color: #F1EFE4;
            border-radius: 20px;

            @media (min-width: 1280px) {
                margin-left: 2%;
                margin-right: 2%;
                border-radius: 30px;
            }

            .container{
                display: flex;
                align-items: center;
                padding-top: 25px;
                padding-bottom: 25px;
                gap: 20px;
    
                @media (min-width: 768px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
    
                @media (max-width: 767px) {
                    flex-wrap: wrap;
                }
            }

            select{
                flex: 0 0 auto;
                width: 100%;
                height: 51px;
                border-radius: 16px;
                border: 2px solid #000;
                padding-left: 32px;
                padding-right: 32px;
                appearance: none;
                background: #fff url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03LjQxMDczIDUuMDQyMTZMMTEuODAzIDAuNTIyMDY0TDEzIDEuNzYzOTlMNi41MTIxOCA4LjVMLTUuNTI0OTllLTA4IDEuNzYzOThMMS4yMjE0MiAwLjQ5OTk5OUw1LjYxMDU5IDUuMDQyMTZMNi41MTIxOCA1LjkyNDczTDcuNDEwNzMgNS4wNDIxNloiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=') no-repeat calc(100% - 19px) 50%;
                background-size: 13px;

                @media (min-width: 768px) {
                    width: 30%;
                }
                @media (min-width: 1280px) {
                    width: 424px;
                }

                option {
                    color: #000000;
                }
                  
                &:invalid,
                option[value=""] {
                    color: #999999;
                }
            }
            
            .filter-icon{
                width: 24px;
                height: 25px;
    
                @media (max-width: 767px) {
                    display: none;
                }
            }

            .clear_filters{
                display: flex;
    
                @media (max-width: 767px) {
                    margin-left: auto;
                    margin-right: auto;
                }

                .filter-clear-icon{
                    width: 21px;
                    height: 21px;
                    margin-right: 6px;
                }
            }
        }

        table{
            width: 100%;
            margin-top: 37px;
            margin-bottom: 37px;

            @media (min-width: 1280px) {
                margin-top: 64px;
                margin-bottom: 64px;
            }
            
            &.more{
                th,
                td{
                    &.title{
                        @media (min-width: 768px) {
                            width: 48%;
                        }
                    }
                }
            }

            tbody{
                tr{
                    border-bottom: 1px solid #CECECB;
    
                    @media (max-width: 767px) {
                        border-top: 1px solid #CECECB;
                        margin-bottom: 16px;
                    }
                }
            }

            tr{
                @media (max-width: 767px) {
                    display: flex;
                    flex-wrap: wrap;
                    position: relative;
                }

                &:nth-child(2){
                    @media (max-width: 767px) {
                        margin-top: 0;
                    }
                }
            }

            th{
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                border-bottom: 1px solid #CECECB;
                padding-bottom: 16px;

                @media (max-width: 767px) {
                    display: none;
                }
            }
            td{
                padding-top: 24px;
                padding-bottom: 24px;
                font-size: 16px;

                @media (min-width: 1280px) {
                    padding-top: 43px;
                    padding-bottom: 43px;
                    font-size: 24px;
                }

                &.title{
                    font-size: 25px;
            
                    @media (min-width: 1280px) {
                        font-size: 40px;
                    }
                }

                &.button_wrapper{
                    text-align: right;

                    .button{
                        font-size: 25px;
                        width: 100%;
                        height: 50px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #000;
                        color: #fff;
                        
                        @media (min-width: 1280px) {
                            font-size: 26px;
                            width: 290px;
                        }
                        @media (max-width: 1279px) {
                            width: 50px;

                            span{
                                display: none;
                            }
                        }
    
                        &:active,
                        &:hover{
                            color: #000;
                            background-color: #fff;

                            svg{
                                filter: invert(1);
                            }
                        }

                        svg{
                            flex: 0 0 auto;
                            width: 30px;
                            height: 30px;

                            @media (min-width: 1280px) {
                                display: none;
                            }
                        }
                    }
                }
            }

            th,
            td{
                padding: 24px 0;
            
                @media (min-width: 768px) {
                    padding-left: 20px;
                }
                @media (min-width: 1280px) {
                    padding-left: 32px;
                }

                &:not(td){
                    padding-top: 0;
                }

                &.title{
                    width: 80%;
                    text-wrap: balance;

                    @media (min-width: 768px) {
                        width: 58%;
                    }
                    @media (min-width: 1280px) {
                        width: 48%;
                    }
                    @media (max-width: 767px) {
                        padding-bottom: 0;
                        hyphens: auto;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                &.type{
                    width: 15%;

                    @media (max-width: 767px) {
                        padding-top: 0;
                        padding-bottom: 0;
                        width: 40%;
                    }
                }
                &.loc{
                    width: 12%;

                    @media (max-width: 767px) {
                        padding-top: 0;
                        width: 40%;
                    }
                }

                &.button_wrapper{
                    width: 25%;

                    @media (max-width: 767px) {
                        padding-top: 0;
                        padding-bottom: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                }

                .mobile_label{
                    display: none;

                    @media (max-width: 767px) {
                        display: block;
                    }
                }
            }
        }
    }

    .apply_now_wrapper{
        background-color: #F1EFE4;
        border-radius: 20px;

        @media (min-width: 1280px) {
            margin-left: 2%;
            margin-right: 2%;
            border-radius: 30px;
        }

        .text_wrapper{
            width: 100%;
        }

        .container{
            padding-top: 38px;
            padding-bottom: 38px;
            gap: 20px;

            @media (min-width: 1280px) {
                padding-top: 50px;
                padding-bottom: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .title{
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 32px;
            text-wrap: balance;
    
            @media (min-width: 1280px) {
                font-size: 40px;
            }
        }

        .button{
            flex: 0 0 auto;
            
            @media (min-width: 1280px) {
                width: 320px;
            }
            @media (max-width: 1279px) {
                margin-top: 42px;
            }
        }
    }
}

.position_details{
    margin-bottom: 48px;
    
    @media (min-width: 1280px) {
        margin-bottom: 74px;
    }

    .description_card{
        border-radius: 40px;
        margin-bottom: 24px;
        padding: 48px 20px;
        color: #fff;

        @media (min-width: 1280px) {
            border-radius: 60px;
            margin-bottom: 36px;
            padding: 72px 10%;
        }

        .title{
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
    
            @media (min-width: 1280px) {
                font-size: 40px;
                margin-bottom: 36px;
            }

            svg{
                width: 25px;
                height: 25px;
                margin-right: 16px;
                flex: 0 0 auto;
    
                @media (min-width: 1280px) {
                    width: 51px;
                    height: 50px;
                    margin-right: 20px;
                }
            }
        }

        .text{
            font-size: 16px;
            line-height: 1.25;
            letter-spacing: -0.024em;

            @media (min-width: 1280px) {
                font-size: 24px;
                
            }

            *{
                color: #fff !important;
                background: transparent !important;
                font-family: inherit !important;

                & + ul,
                & + ol{
                    margin-top: 12px;
                    margin-bottom: 16px;
                }
            }
        
            ul{
                list-style: none;

                li{
                    padding-left: 32px;
    
                    @media (min-width: 1280px) {
                        padding-left: 40px;
                    }

                    &:before{
                        content: '- ';
                        display: inline-flex;
                        margin-right: 12px;
                        margin-left: -25px;
    
                        @media (min-width: 1280px) {
                            margin-left: -21px;
                        }
                    }
                }
            }
        
           ol{
                list-style: none;
                counter-reset: ol_count;

                li{
                    padding-left: 40px;

                    &:before{
                        counter-increment: ol_count;
                        content: counter(ol_count) ".";
                        display: inline-flex;
                        margin-right: 12px;
                        margin-left: -25px;
                    }
                }
            }

            a{
                text-decoration: underline;

                &:active,
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .button_wrapper{
        margin-top: 56px;
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }

        .button + .button{
            margin-top: 24px;

            @media (min-width: 768px) {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }
}