.AB-ContactPersons {
    line-height: 1.25;
    margin: 64px 0;
            
    @media (min-width: 768px) {
        margin: 96px 0;
    }
    @media (min-width: 1280px) {
        margin: 140px 0;
    }

    .container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        & > .headline{
            font-size: 48px;
                
            @media (min-width: 1280px) {
                font-size: 68px;
            }
    
            em{
                font-style: normal;
                text-transform: uppercase;
                @apply font-saa-series;
            }
            p{
                @media (max-width: 767px) {
                    display: inline-block;
                }
            }
        }
        .text{
            font-size: 16px;
    
            @media (min-width: 1280px) {
                font-size: 24px;
                
            }
        }
        
        & > .headline,
        .text,
        .item{
            margin-bottom: 24px;

            @media (min-width: 1024px) {
                flex: 0 0 auto;
                width: 49%;
                width: calc(50% - 1.25rem / 2);
            }
            @media (min-width: 1280px) {
                margin-bottom: 57px;
            }
        }
        
        .button_wrapper{
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 auto;
            width: 100%;
            justify-content: center;
            gap: 30px;
            margin-bottom: 50px;

            @media (min-width: 1024px) {
                margin-top: 48px;
                margin-bottom: 96px;
            }
            @media (min-width: 1280px) {
                margin-bottom: 57px;
            }

            .button{
                font-size: 25px;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.white{
                    background-color: #fff;
                    color: #000;

                    &:active,
                    &:hover{
                        color: #fff;
                        background-color: #000;
                    }
                }

                @media (min-width: 768px) {
                    width: 49%;
                    width: calc(50% - 30px / 2);
                }
                @media (min-width: 1024px) {
                    width: 324px;
                    font-size: 26px;
                }
                @media (min-width: 1440px) {
                    padding-top: 4px;
                }
            }
        }

        .item{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            background-color: #3E60CB;
            padding: 30px 20px;
            color: #fff;
            margin-bottom: 0;

            @media (min-width: 768px) {
                padding: 20px;
            }
            @media (min-width: 1024px) and (max-width: 1279px) {
                width: 49%;
                width: calc(100% / 2 - 20px / 2);
            }
            @media (min-width: 1280px) {
                padding: 48px;
                margin-bottom: 0;
            }
            @media (max-width: 767px) {
                width: 100%;
            }

            .headline{
                @media (max-width: 767px) {
                    min-height: 83px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .item_inner{
                display: flex;
                height: 100%;
            }

            .text_inner{
                @media (min-width: 1024px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            .image{
                flex: 0 0 auto;
                width: 65px;
                margin-right: 21px;
                border-radius: 15px;

                @media (min-width: 768px) {
                    margin-right: 16px;
                    width: 110px;
                }
                @media (min-width: 1280px) {
                    margin-right: 48px;
                    width: 150px;
                }
            }

            .name{
                font-size: 25px;
                line-height: 1.25;
                text-transform: uppercase;
                @apply font-saa-series;
                margin-bottom: 10px;

                @media (min-width: 768px) {
                    margin-bottom: 6px;
                }

                @media (min-width: 1280px) {
                    font-size: 40px;
                }
            }

            .text_val,
            .phone,
            .email{
                font-size: 16px;
                line-height: 1.25;
        
                @media (min-width: 1280px) {
                    font-size: 24px;
                }
            }

            .text_val{
                margin-bottom: 0;

                @media (min-width: 768px) {
                    margin-bottom: 20px;
                }
            }

            .phone,
            .email{
                display: flex;
                gap: 6px;

                @media (max-width: 767px) {
                    margin-left: -86px;
                    margin-top: 24px;
                }
                
                a{
                    position: relative;
                    text-decoration: none !important;
                    white-space: nowrap;
                    
                    &:after{
                        content: '';
                        background-color: #fff;
                        height: 1px;
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        right: 0;
                        transition: background .1s;
                    }

                    &:active,
                    &:hover{
                        &:after{
                            background-color: transparent;
                        }
                    }
                }

                &.email{
                    margin-top: 20px;

                    @media (min-width: 768px) {
                        margin-top: 6px;
                    }
                }
            }
        }
    }
}
