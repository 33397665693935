.AB-Benefits {
    .headline{
        font-size: 48px;
            
        @media (min-width: 1280px) {
            font-size: 68px;
        }

        em{
            font-style: normal;
            text-transform: uppercase;
            @apply font-saa-series;
        }
    }

    .line_wrapper{
        counter-reset: lines_counter;
        padding: 32px 0;
            
        @media (min-width: 1280px) {
            padding: 48px 0 64px;
        }

        .benefits-icon{
            position: absolute;
            width: 50px;
            height: 50px;
            
            @media (min-width: 1280px) {
                width: 77px;
                height: 77px;
            }
        }

        .line_inner{
            .item{
                flex: 0 0 auto;
                height: 50px;
                border-radius: 38px;
                flex-grow: 1;
                background: #DE6B084D;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 24px 0 62px;
                transition: ease-in .2ms;
            
                @media (min-width: 1280px) {
                    padding: 0 50px 0 101px;
                    height: 77px;
                    width: 50%;
                }

                &:first-child{
                    background: #DE6B08;
                }
                &:not(:first-child){
                    @media (min-width: 1280px) {
                        margin-left: -50%;
                        padding-left: 53%;
                    }
                }
                &.active{
                    justify-content: space-between;

                    @media (max-width: 1279px) {
                        background: #DE6B08;
                    }

                    a{
                        &.next{
                            display: block;
                        }
                    }
                }
                &:not(.active){
                    @media (max-width: 1279px) {
                        display: none;
                    }
                }

                a{
                    text-decoration: none !important;
                    font-size: 25px;
                    color: #fff;
            
                    @media (min-width: 1280px) {
                        font-size: 40px;
                    }

                    &.num{
                        @apply font-saa-series;
                        position: relative;
                        z-index: 1;
                    }

                    &.next{
                        display: none;

                        .benefits-icon-arrow{
                            width: 30px;
                            height: 30px;
                            position: relative;
                            margin-right: -8px;
            
                            @media (min-width: 1280px) {
                                width: 45px;
                                height: 45px;
                            }
                        }
                    }
                }
            }
        }

        &.water{
            .line_inner{
                .item{
                    background: #6ca38b4D;
    
                    &:first-child{
                        background: #6ca38b;
                    }
                    &.active{
                        @media (max-width: 1279px) {
                            background: #6ca38b;
                        }
                    }
                }
            }
        }
    }

    .items_wrapper{
        .item{
            .title{
                font-size: 25px;
        
                @media (min-width: 1280px) {
                    font-size: 40px;
                    
                }
            }
            .text{
                font-size: 16px;
        
                @media (min-width: 1280px) {
                    font-size: 24px;
                }
            }
        }

        &:not(.edit){
            .item:not(:first-child){
                display: none;
            }
        }
    }
    
}
