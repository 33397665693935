.AB-ImageGallery {
    line-height: 1.25;
    margin: 84px 0;
            
    @media (min-width: 768px) {
        margin: 96px 0;
    }
    @media (min-width: 1280px) {
        margin: 120px 0;
    }

    .headline{
        font-size: 48px;
            
        @media (min-width: 1280px) {
            font-size: 68px;
        }

        em{
            font-style: normal;
            text-transform: uppercase;
            @apply font-saa-series;

            @media (max-width: 767px) {
                display: block;
                line-height: 1;
            }
        }
        p{
            @media (max-width: 767px) {
                display: inline-block;
            }
        }
    }

    .images_wrapper{
        margin-top: 37px;
        overflow: hidden;

        @media (min-width: 1280px) {
            margin-top: 64px;
        }

        &:not(.edit){
            gap: 20px;
            display: flex;

            .image{
                &:nth-child(2n){
                    @media (min-width: 1280px) {
                        margin-top: 80px;
                        padding-bottom: 80px;
                    }
                }
    
                @media (max-width: 767px) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        &.edit{
            & > div > div{
                display: flex;
                flex-wrap: wrap;

                .pimcore_block_entry{
                    flex: 0 0 auto;
                    width: 20%;
                }
            }
        }
    }

    .controls{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 37px;

        @media (min-width: 1280px) {
            display: none !important;
        }

        a{
            flex: 0 0 auto;
            position: relative;
            z-index: 1;

            &.next{
                transform: scaleX(-1);
            }

            svg{
                height: 51px;
                width: 51px;
            }
        }

        .slides_count{
            height: 50px;
            border: 2.24px solid #000;
            font-size: 25px;
            padding: 0 57px;
            border-left: 0;
            border-right: 0;
            display: flex;
            align-items: center;
            margin-left: -25px;
            margin-right: -25px;
            width: auto;

            span{
                font-style: normal;
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
}
