.AB-FullsizeImageTextPosition {
    .title_wrapper{
        margin-bottom: 18px;

        .line{
            display: flex;
            align-items: center;

            img{
                width: 32px;
                height: 32px;
                margin-right: 20px;

                @media (min-width: 768px) {
                    margin-right: 40px;
                }
            }

            div[data-type="image"]{
                flex: 0 0 auto;
                width: 100px;

                img{
                    width: 150px;
                    height: 32px;
                    object-fit: contain;
                }
            }
            div[data-type="input"]{
                flex-grow: 1;
                margin-left: 40px;
            }

            .headline{
                @media (max-width: 767px) {
                    display: none;
                }
            }

            .spacer{
                flex-grow: 1;
                position: relative;
                margin-left: 24px;
                margin-right: 24px;

                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    height: 1px;
                    background-color: #000;
                    transform: translateY(-50%);
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
    }

    .img_wrapper{
        .desktop{
            @media (max-width: 1179px) {
                display: none;
            }
        }
        .tablet{
            @media (max-width: 767px) or (min-width: 1200px) {
                display: none;
            }
        }
        .mobile{
            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    .text_wrapper{
        font-size: 16px;

        @media (min-width: 1280px) {
            font-size: 24px;
        }

        h2{
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 16px;
                
            @media (min-width: 1280px) {
                font-size: 40px;
                margin-bottom: 24px;
            }
        }
    }

    .button{
        &.new{
            margin-top: 20px;
                            
            @media (min-width: 1280px) {
                margin-top: 32px;
            }
        }
    }
}
