header{
    .contact_wrapper{
        @media (min-width: 375px) {
            padding-left: 1.25rem;
        }

        & > div{
            flex: 0 0 auto;
            max-width: 90%;

            @media (min-width: 360px) {
                max-width: 260px;
            }
        }

        a.social_link{
            svg{
                filter: invert(1);
            }
    
            &:active,
            &:hover{
                svg{
                    filter: none;
                }
            }
        }
    }
}
