.AB-ContactFormKarriere {
    margin-top: 48px;
    margin-bottom: 48px;

    @media (min-width: 768px) {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    @media (min-width: 1280px) {
        margin: 120px 2%;
    }

    &.is_disabled{
        opacity: 0.4;
    }

    .inner_wrapper{
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 40px;
        background-color: #87B5CB;

        @media (min-width: 1280px) {
            border-radius: 60px;
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    .contact-form{
        .grid{
            @media (max-width: 1279px) {
                display: flex;
                flex-direction: column;
                position: relative;
            }
        }

        .select_wrapper,
        .form-control{
            margin-bottom: 16px;

            &.select_wrapper{
                label{
                    display: none;
                }
            }
        }

        .checkbox-container{
            margin-top: 48px;
            margin-bottom: 0 !important;

            @media (min-width: 1280px) {
                margin-top: 48px;
            }
            @media (max-width: 1279px) {
                position: absolute;
                bottom: 82px;
            }

            .checkbox-label{
                padding-left: 0;
                padding-right: 0;
                color: #fff;
                opacity: 1;
                display: flex;
                align-items: center;
                position: static;
                transform: none;

                a{
                    text-decoration: underline;

                    &:active,
                    &:hover{
                        text-decoration: none;
                    }
                }

                .checkbox{
                    margin-bottom: 0 !important;
                    width: 24px;
                    height: 24px;
                    background-color: #fff;

                    &:after{
                        color: #000;
                    }
                }
            }
        }

        .attachment_row{
            margin-bottom: 16px;

            @media (min-width: 768px) {
                display: flex;
                align-items: center;
            }

            & > label{
                padding-right: 22px;
                z-index: 1;
                width: 145px;
                padding-top: 2px;
            }
            
            .attachment{
                flex-grow: 1;
                position: relative;

                label{
                    border: 2px dashed #fff;
                    border-radius: 20px;
                    height: 51px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    cursor: pointer;
                    padding-left: 16px;
                    padding-right: 16px;
                    line-height: 1.25;

                    @media (max-width: 767px) {
                        margin-top: 12px;
                    }
                    
                    svg{
                        width: 22px;
                        height: 23px;
                        margin-right: 16px;
                    }
                }
                input{
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0, 0, 0, 0);
                    white-space: nowrap;
                    border: 0;
                }

                .form-control{
                    margin-bottom: 0;
                }

                .added-files{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;

                    u{
                        background-color: #adcbda;
                        height: 51px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                        position: relative;
                        border-radius: 20px;
                        text-decoration: none;
                        padding-left: 22px;
                        padding-right: 11px;

                        i{
                            font-style: normal;
                            max-width: 94%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        a{
                            flex: 0 0 auto;
                            width: 50px;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg{
                                width: 21px;
                                height: 21px;
                            }
                        }
                    }
                }
            }
        }

        select,
        input{
            margin-top: 0;
            padding: 0 22px;
            height: 51px;
        }

        select{
            option {
                color: #000000;
            }
              
            &:invalid,
            option[value=""] {
                color: #999999;
            }
        }

        .right_col{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        button{
            font-size: 25px;
            width: 100%;
            height: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            color: #fff;
            
            @media (min-width: 1280px) {
                font-size: 26px;
                margin-bottom: -14px;
            }
            @media (max-width: 1279px) {
                margin-top: 138px;
            }
            @media (max-width: 767px) {
                margin-top: 316px;
            }

            &:active,
            &:hover{
                color: #000;
                background-color: #fff;
            }

            span{
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
    }

    .success_wrapper,
    .error_wrapper{
        margin-top: 20px;
        padding: 48px 0;
        color: #fff;
        border-radius: 40px;
        text-align: center;

        @media (min-width: 768px) {
            padding: 64px 0;
        }
        @media (min-width: 1280px) {
            padding: 92px 0;
            border-radius: 60px;
        }

        .title{
            font-size: 48px;
                
            @media (min-width: 1280px) {
                font-size: 60px;
            }
        }
        .text{
            font-size: 16px;
    
            @media (min-width: 1280px) {
                font-size: 24px;
                
            }
        }
    }
}