.AB-Testimonials {
    line-height: 1.25;
    margin: 0;
            
    @media (min-width: 1024px) {
        padding-top: 50px;
        margin: 0 0 96px;
    }
    @media (min-width: 1280px) {
        padding-top: 64px;
        padding-bottom: 48px;
        margin: 64px 0;
    }

    .container{
        display: flex;
        flex-wrap: wrap;

        .headline{
            font-size: 48px;
                
            @media (min-width: 1280px) {
                font-size: 68px;
            }
    
            em{
                font-style: normal;
                text-transform: uppercase;
                @apply font-saa-series;
            }
            p{
                @media (max-width: 767px) {
                    display: inline-block;
                }
            }
        }
        .text{
            font-size: 16px;
    
            @media (min-width: 1280px) {
                font-size: 24px;
                
            }
        }
        
        .headline,
        .text,
        .item{
            margin-bottom: 24px;

            @media (min-width: 768px) {
                flex: 0 0 auto;
                width: 49%;
                width: calc(50% - 1.25rem / 2);
            }
            @media (min-width: 1280px) {
                margin-bottom: 57px;
            }
        }

        .headline,
        .text{
            @media (min-width: 768px) and (max-width: 1023px) {
                width: 100%;
            }
        }

        .item{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            border-bottom: 2px solid #CECECB;
            padding-top: 30px;
            padding-bottom: 30px;
            margin-bottom: 48px;

            @media (min-width: 1280px) {
                padding-top: 48px;
                padding-bottom: 48px;
                margin-bottom: 0;
            }

            &:before{
                @apply font-saa-series;
                content: '“';
                color: #3E60CB;
                font-size: 180px;
                position: absolute;
                right: 0;
                top: -62px;
            }

            .item_inner{
                display: flex;
                
                @media (min-width: 1024px) {
                    height: 100%;
                }
            }

            .text_inner{
                @media (min-width: 1024px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }

            .image{
                flex: 0 0 auto;
                width: 65px;
                margin-right: 21px;

                @media (min-width: 1280px) {
                    margin-right: 48px;
                    width: 150px;
                }
            }

            .name_wrapper{
                margin-top: 16px;
    
                @media (min-width: 1280px) {
                    margin-top: 24px;
                }

                .name{
                    font-size: 25px;
                    line-height: 1.25;
                    text-transform: uppercase;
                    @apply font-saa-series;
    
                    @media (min-width: 1280px) {
                        font-size: 40px;
                    }
                }
            }
            .text_val,
            .occupation{
                font-size: 16px;
                line-height: 1.25;
        
                @media (min-width: 1280px) {
                    font-size: 24px;
                    
                }

                &.text_val{
                    &.mobile{
                        display: none;

                        @media (max-width: 1023px) {
                            display: block;
                            margin-bottom: 32px;
                        }
                    }
                    &.desktop{
                        @media (max-width: 1023px) {
                            display: none;
                        }
                    }
                    
                }
            }
        }
    }
}
