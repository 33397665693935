.nav-list {
    &::-webkit-scrollbar {
        @apply w-[3px];
    }

    &::-webkit-scrollbar-track {
        @apply bg-[#f1f1f1] rounded-full;
    }

    &::-webkit-scrollbar-thumb {
        @apply bg-black rounded-full;
    }
}
