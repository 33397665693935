.AB-TrainingDates {
    .headline_wrapper{
        @media (min-width: 1280px) {
            display: flex;
            flex-wrap: wrap;
        }

        .headline{
            font-size: 48px;
                
            @media (min-width: 1280px) {
                font-size: 60px;
            }
            @media (max-width: 767px) {
                hyphens: auto;
            }
    
            em{
                font-style: normal;
                text-transform: uppercase;
                @apply font-saa-series;
            }
            p{
                @media (max-width: 767px) {
                    display: inline-block;
                }
            }
        }
        .text{
            font-size: 16px;
    
            @media (min-width: 1280px) {
                font-size: 24px;
                
            }
        }

        .headline,
        .text{
            margin-bottom: 24px;
    
            @media (min-width: 1280px) {
                flex: 0 0 auto;
                width: 49%;
                width: calc(50% - 1.25rem / 2);
                margin-bottom: 57px;
            }
        }
    }

    .table_wrapper{

        .headline,
        .headline_more{
            font-size: 44px;
    
            @media (min-width: 1280px) {
                font-size: 40px;
            }

            &.desktop{
                @media (max-width: 767px) {
                    display: none;
                }
            }

            &.mobile{
                display: none;

                @media (max-width: 767px) {
                    display: block;
                    margin-bottom: 0 !important;
                }
            }

            &.headline_more{
                margin-top: 123px;
                margin-bottom: 32px;

                @media (min-width: 768px) {
                    margin-top: 64px;
                    margin-bottom: 64px;
                }
                @media (min-width: 1280px) {
                    margin-top: 144px;
                    margin-bottom: 48px;
                }

                + table {
                    margin-top: 0;
                }
            }
        }

        .table_header{
            padding: 17px 20px;
            border-radius: 20px;
        
            @media (min-width: 1280px) {
                border-radius: 38px;
                padding: 26px 50px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .pimcore_wysiwyg{
                min-height: auto !important;
            }
            .text{
                color: #fff;
                display: flex;
                align-items: center;

                .icon-alert-triangle{
                    width: 30px;
                    height: 31px;
                    margin-right: 8px;
                    
                    @media (max-width: 1279px) {
                        display: none;
                    }
                }
                    
                @media (max-width: 767px) {
                    display: none;
                }
            }
            

            .headline,
            .text{
                margin-bottom: 24px;
        
                @media (min-width: 1280px) {
                    flex: 0 0 auto;
                    width: 49%;
                    width: calc(50% - 1.25rem / 2);
                    margin-bottom: 0;
                }
            }
        }

        &.edit{
            .table_header{
                .headline{
                    flex: 0 0 auto;
                    width: 100%;
    
                    &.mobile{
                        display: block !important;
                    }
                }
            }
        }

        table{
            width: 100%;
            margin-top: 48px;
            
            &.more{
                th,
                td{
                    &.title{
                        @media (min-width: 768px) {
                            width: 50%;
                        }
                    }
                }
            }

            tr{
                @media (max-width: 767px) {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                }

                &:not(:first-child){
                    border-bottom: 1px solid #CECECB;
    
                    @media (max-width: 767px) {
                        border-top: 1px solid #CECECB;
                        margin-top: 16px;
                    }
                }

                &:nth-child(2){
                    @media (max-width: 767px) {
                        margin-top: 0;
                    }
                }
            }

            th{
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                border-bottom: 1px solid #CECECB;
                padding-bottom: 16px;

                @media (max-width: 767px) {
                    display: none;
                }
            }
            td{
                padding-top: 24px;
                padding-bottom: 24px;
                font-size: 16px;

                @media (min-width: 1280px) {
                    padding-top: 43px;
                    padding-bottom: 43px;
                    font-size: 24px;
                }

                &.title{
                    font-size: 25px;
            
                    @media (min-width: 1280px) {
                        font-size: 40px;
                    }
                }

                &.button_wrapper{
                    text-align: right;

                    .button{
                        font-size: 25px;
                        width: 100%;
                        height: 50px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #fff;
                        color: #000;
                        
                        @media (max-width: 767px) {
                            width: 50px;
                        }
                        @media (min-width: 1280px) {
                            font-size: 26px;
                            width: 290px;
                        }
    
                        &:active,
                        &:hover{
                            color: #fff;
                            background-color: #000;

                            svg{
                                filter: invert(1);
                            }
                        }

                        span{
                            @media (max-width: 767px) {
                                display: none;
                            }
                        }

                        svg{
                            width: 24px;
                            height: 24px;

                            @media (min-width: 768px) {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }

            th,
            td{
                padding: 24px 0;
            
                @media (min-width: 768px) {
                    padding-left: 20px;
                }
                @media (min-width: 1280px) {
                    padding-left: 32px;
                }

                &.title{
                    width: 30%;

                    @media (max-width: 767px) {
                        padding-bottom: 0;
                        width: 80%;
                    }
                }
                &.date{
                    width: 20%;

                    @media (max-width: 767px) {
                        padding-top: 0;
                        padding-bottom: 0;
                        width: 80%;
                    }
                }
                &.time,
                &.info{
                    width: 20%;

                    @media (max-width: 767px) {
                        padding-top: 0;
                        width: 80%;
                    }
                }

                &.button_wrapper{
                    @media (max-width: 767px) {
                        padding-top: 0;
                        padding-bottom: 0;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .edit_wrapper{
            margin-top: 48px;

            .header,
            .item{
                margin-bottom: 36px;
                display: flex;

                & > div{
                    flex-grow: 1;

                    &.title{
                        flex-grow: initial;
                        flex: 0 0 auto;
                        width: 50%;
                    }
                }

                &.item{
                    font-size: 16px;

                    @media (min-width: 1280px) {
                        font-size: 24px;
                    }

                    &.title{
                        font-size: 25px;
                
                        @media (min-width: 1280px) {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }


}
