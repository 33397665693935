@layer components {
    .admin {
        &-field {
            @apply p-2 bg-road/30 border border-road rounded-md;
        }

        &-note {
            @apply my-8 p-4 text-16 text-black bg-admin-blue-200 border border-admin-blue-500 rounded-xl;
        }

        &-warning {
            @apply my-8 p-4 text-16 text-black bg-admin-orange-200 border border-admin-orange-500 rounded-xl;
        }

        &-media-grid > .pimcore_editable_block {
            @apply grid grid-flow-row gap-4 sm:grid-cols-2 sm:gap-y-8 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5;

            .pimcore_editable_image,
            .video-editmode,
            .pimcore_editable_video,
            .pimcore_editable_video_empty {
                width: 100% !important;
                height: auto !important;
                @apply aspect-square;

                img,
                video {
                    @apply w-full h-full object-cover object-center;
                }
            }
        }

        &-media-grid.aspect-ratio-video > .pimcore_editable_block {
            .pimcore_editable_image,
            .video-editmode,
            .pimcore_editable_video,
            .pimcore_editable_video_empty {
                @apply aspect-video;
            }
        }
    }
}
