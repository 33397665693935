.AB-FullsizeColorImageTextKarriere {
    margin-top: 48px;
    margin-bottom: 48px;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px;
    border-radius: 40px;
    background-color: #3E60CB;

    @media (min-width: 768px) {
        margin-top: 64px;
        margin-bottom: 64px;
    }
    @media (min-width: 1280px) {
        margin: 120px 2%;
        border-radius: 60px;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .bg_icon{
        width: 142px;
        height: 142px;
        position: absolute;

        @media (min-width: 1280px) {
            width: 234px;
            height: 234px;
            margin-left: -16px;
        }
    }

    .headline_wrapper{
        margin-bottom: 20px;

        @media (min-width: 1280px) {
            margin-bottom: 48px;
            display: flex;
            justify-content: space-between;
        }

        .text_col,
        .img_col{
            @media (min-width: 1280px) {
                flex: 0 0 auto;
                width: 49%;
                width: calc(50% - 1.25rem / 2);
            }

            &.text_col{

                @media (min-width: 1280px) {
                    padding-top: 167px;
                }
            }

            &.img_col{
                overflow: hidden;

                @media (max-width: 1279px) {
                    margin-top: 42px;
                }
                @media (max-width: 767px) {
                    margin-top: 36px;
                }

                .image{
                    display: flex;
                    justify-content: center;
                }
    
                img{
                    border-radius: 40px;
    
                    @media (min-width: 1280px) {
                        border-radius: 60px;
                    }
                }
    
                .controls{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 37px;
            
                    a{
                        flex: 0 0 auto;
                        position: relative;
                        z-index: 1;
                        
                        &:not(.swiper-button-disabled){
                            svg{
                                //background-color: #fff;
                            }
                        }
            
                        &.next{
                            transform: scaleX(-1);
                        }
            
                        svg{
                            height: 51px;
                            width: 51px;
                        }
                    }
            
                    .slides_count{
                        height: 50px;
                        border: 2.24px solid #000;
                        font-size: 25px;
                        padding: 0 57px;
                        border-left: 0;
                        border-right: 0;
                        display: flex;
                        align-items: center;
                        margin-left: -25px;
                        margin-right: -25px;
                        width: auto;
                        color: #fff;
            
                        span{
                            font-style: normal;
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                    }
                }
            }
        }
        
        .headline,
        .text{
            width: 100%;
            color: #fff;

            &.headline{
                @media (max-width: 1279px) {
                    padding-top: 84px;
                }
            }
        }

        .button{
            font-size: 25px;
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-width: 1024px) {
                width: 290px;
                font-size: 26px;
            }
            @media (min-width: 1440px) {
                padding-top: 4px;
            }

            &.white{
                background-color: #fff;
                color: #000;

                &:active,
                &:hover{
                    color: #fff;
                    background-color: #000;
                }
            }
        }
    }

    .awards{
        &.edit{
            .logo_list > div{
                display: flex;
                justify-content: left;
                gap: 20px;
                width: 100%;

                .pimcore_block_entry{
                    background-color: #fff;
                    flex: 0 0 auto;
                    width: 32%;
                    width: calc(100% / 3 - 40px / 3);
                    padding-top: 17px;
                    padding-bottom: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
        
                    @media (min-width: 1280px) {
                        padding-top: 29px;
                        padding-bottom: 29px;
                    }
                }
            }
        }
        &:not(.edit){
            .logo_list{
                display: flex;
                justify-content: left;
                gap: 20px;

                @media (max-width: 767px) {
                    flex-wrap: wrap;
                }
    
                .logo{
                    background-color: #fff;
                    flex: 0 0 auto;
                    width: 100%;
                    padding-top: 17px;
                    padding-bottom: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 30px;
        
                    @media (min-width: 768px) {
                        display: flex;
                        justify-content: center;
                        width: 49%;
                        width: calc(100% / 2 - 20px / 2);
                    }
                    @media (min-width: 1280px) {
                        padding-top: 29px;
                        padding-bottom: 29px;
                        width: 32%;
                        width: calc(100% / 3 - 40px / 3);
                    }
                    @media (max-width: 767px) {
                        min-height: 140px;

                        picture{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        img{
                            max-height: 106px;
                            width: auto;
                        }
                    }
                }
            }
        }

        .title{
            color: #fff;
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 32px;
    
            @media (min-width: 1280px) {
                font-size: 40px;
                margin-bottom: 58px;
            }
        }
    }
}