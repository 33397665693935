@tailwind base;
@tailwind components;
@tailwind utilities;

@import "admin";
@import "base";
@import "buttons";
@import "common";
@import "components";
@import "gdpr-consent";
@import "inputs";
@import "import-fonts";
@import "lenis";
@import "plyr";
@import "swiper";
@import "wysiwyg";

@import "elements/common";
@import "elements/menu";
@import "elements/header";
@import "elements/footer";
@import "elements/benefits";
@import "elements/twoColumnTextBoxes";
@import "elements/twoColumnHeadlineText";
@import "elements/FullsizeImageText";
@import "elements/testimonials";
@import "elements/contactPersons";
@import "elements/warningText";
@import "elements/trainingDates";
@import "elements/imageGallery";
@import "elements/logoList";
@import "elements/vacancies";
@import "elements/fullsizeColorImageTextKarriere";
@import "elements/contactFormKarriere";
@import "elements/FullsizeImageTextPosition";
