.AB-SupplyChain {
    .static-container {
        .edit .not-last-bar {
            bottom: -105px !important;
        }

        .wysiwyg.subheadline {
            @apply text-24 z-10 sm:text-32 lg:text-32;

            p {
                @apply inline pr-3 box-decoration-clone bg-white;
            }

            .highlight {
                @apply text-32 font-saa-series sm:text-40 lg:text-42;
            }
        }
    }

    .animated-container {
        .wysiwyg.subheadline {
            @apply text-24 z-10 sm:text-32 md:text-36 xl:text-60;

            .highlight {
                @apply text-32 font-saa-series sm:text-40 md:text-[45px] xl:text-78;
            }
        }
    }
}
