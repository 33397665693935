.headline_wrapper{
    @media (min-width: 1280px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .headline{
        font-size: 48px;
        line-height: 1.25;
            
        @media (min-width: 1280px) {
            font-size: 60px;
        }
        @media (max-width: 767px) {
            hyphens: auto;
        }

        em{
            font-style: normal;
            text-transform: uppercase;
            @apply font-saa-series;
        }
        p{
            @media (max-width: 767px) {
                display: inline-block;
            }
        }
    }
    .text{
        font-size: 16px;

        @media (min-width: 1280px) {
            font-size: 24px;
            
        }
    }

    .headline,
    .text{
        margin-bottom: 24px;

        @media (min-width: 1280px) {
            flex: 0 0 auto;
            width: 49%;
            width: calc(50% - 1.25rem / 2);
            margin-bottom: 57px;
        }
    }
}


.button.new{
    font-size: 25px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1024px) {
        width: 290px;
        font-size: 26px;
    }
    @media (min-width: 1440px) {
        padding-top: 4px;
    }

    &.white{
        background-color: #fff;
        color: #000;

        &:active,
        &:hover{
            color: #fff;
            background-color: #000;
        }
    }
}