.AB-WarningText {
    .wrapper{
        background-color: #3E60CB;
        color: #fff;
        padding-top: 48px;
        padding-bottom: 48px;

        @media (min-width: 768px) {
            padding-top: 38px;
            padding-bottom: 38px;
        }
        @media (min-width: 1280px) {
            padding-top: 63px;
            padding-bottom: 63px;
        }

        .headline{
            font-size: 25px;
            line-height: 1.25;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
                
            @media (min-width: 1280px) {
                font-size: 40px;
                margin-bottom: 35px;
            }

            .pimcore_wysiwyg{
                min-height: auto !important;
            }
        }

        .text_val{
            font-size: 16px;
            line-height: 1.25;

            @media (min-width: 1280px) {
                font-size: 24px;
            }
        }

        .warning-icon{
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            margin-right: 13px;

            @media (min-width: 1280px) {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
        }
    }
}
