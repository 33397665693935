.AB-FullsizeImageText {
    .wysiwyg {
        .highlight {
            @apply text-42 font-saa-series lg:text-32 xl:text-36;
        }

        .topline {
            @apply text-24 md:text-20 xl:text-24;
        }
    }
}
