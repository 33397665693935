.AB-TwoColumnHeadlineText {
    ul[data-last-divider="true"] {
        .pimcore_editable_block {
            @apply divide-y border-b;
        }
    }
    
    ul[data-last-divider="false"] {
        .pimcore_editable_block {
            @apply divide-y;
        }
    }
    
    .wysiwyg {
        .highlight {
            @apply font-saa-series text-24 xl:text-30;
        }
    }
}
